
import {defineComponent, onMounted} from "vue";
import EmployeeOverviewTable from "@/components/widgets/tables/Employees.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";


import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {Api} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "Dashboard",
  components: {
    EmployeeOverviewTable,
    MixedWidget2,
  },
  setup() {
    return {
      dataApi: Api.ALL_EMPLOYEES
    }
  },
  data() {
    return {
      stats: {}
    }
  },
  mounted: function () {
    setCurrentPageTitle("Dashboard");
    this.getStats()
  },
  methods: {
    getStats() {
      ApiService.get(Api.OVERVIEW_STATS).then(response => {
        this.stats = response.data
      })
    }
  }
});
